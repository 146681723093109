<template>
  <div class="exam-container">
    <div style="height: 100%; padding: 0px 100px; box-sizing: border-box">
      <div
        class="exam-header"
        :style="{
          height: exam_header_height + 'vh',
          lineHeight: exam_header_height + 'vh',
        }"
      >
        <img
          src="../../assets/go_home.png"
          style="width: 44px; height: 44px; cursor: pointer"
          @click="goHome"
        />
        <div
          class="content-menu"
          @click="showNavMenuDrawer = true"
          style="margin-left: 20px"
        >
          <img
            src="../../assets/icon_menu.png"
            style="width: 20px; height: 19px; object-fit: cover"
          />
          <p style="margin-left: 5px">Content</p>
        </div>
        <div
          class="collection-container"
          @click="goCollectionList"
          v-if="user_type == 'student'"
        >
          <img
            src="../../assets/icon_star.png"
            style="width: 20px; height: 20px; object-fit: cover"
          />
          <p style="margin-left: 5px">Collection List</p>
        </div>
      </div>
      <div class="exam-content" :style="{ height: exam_content_height + 'vh' }">
        <ExamContentView
          :session_name="session_info.session_info.session_name"
          :content_info="content_info"
          :quiz_pics="quiz_info && quiz_info.quiz_pics"
          style="flex: 1; height: 100%; overflow-y: auto"
        />
        <div style="width: 30px"></div>
        <ExamOptionView
          ref="examOptionView"
          v-if="quiz_info"
          :quiz-info="quiz_info"
          :quiz_pos="indexInfo.quiz_pos"
          :quiz_index="indexInfo.quiz_index"
          :collection_info="collection_info"
          @exchangeCollection="handleCollectionClick"
          @showReviewQuestion="goReviewQuestion"
          @submitAnswer="gotoSubmitAnswer"
          style="flex: 1; height: 100%; overflow-y: auto"
        />
      </div>
    </div>
    <el-drawer
      :modal-append-to-body="false"
      title="Content List"
      :visible.sync="showNavMenuDrawer"
      direction="ltr"
    >
      <ExamNavMenuView
        :class_name="class_info.class_info.class_name"
        :class_catalogues="classCatalogues"
        @showSpecialQuestion="goShowSpecialQuestion"
      />
    </el-drawer>

    <el-dialog
      :visible.sync="showCollectionDialog"
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      custom-class="showCustomDialogStyle"
    >
      <div class="dialog-container">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
          "
        >
          <p
            style="
              margin: 0;
              color: #333333;
              font-size: 24px;
              font-weight: bold;
            "
          >
            Add into My Collection
          </p>
          <div style="position: absolute; right: 32px">
            <img
              src="../../assets/icon_close.png"
              style="width: 24px; height: auto; cursor: pointer"
              @click="showCollectionDialog = false"
            />
          </div>
        </div>
        <div
          style="
            width: 100%;
            padding: 0px 60px;
            margin-top: 40px;
            box-sizing: border-box;
            text-align: center;
          "
        >
          <el-input
            type="textarea"
            :rows="5"
            placeholder="You can enter notes here to this question"
            v-model="collection_note"
          ></el-input>
          <p class="btn-confirm-send" @click="doCollectionQuiz">Confirm</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getClassCatalogue,
  requestClassRecordData,
  submitLearningAnswer,
  collectionQuiz,
  removeCollectionQuiz,
} from "../../api/index";
import { getUserId, getUserType } from "../../utils/store";
import ExamOptionView from "./ExamOptionView.vue";
import ExamContentView from "./ExamContentView";
import ExamNavMenuView from "./ExamNavMenuView";
import { Loading } from "element-ui";
// import '../../assets/common/font.css'

export default {
  name: "StudentExam",
  components: { ExamContentView, ExamOptionView, ExamNavMenuView },
  data() {
    return {
      user_type: getUserType(),
      collection_note: "",
      showCollectionDialog: false,
      toastFinished: false,
      showNavMenuDrawer: false,
      exam_header_height: 14,
      exam_content_height: 0,
      user_id: getUserId(),
      class_id: this.$route.query.class_id,
      record_id: this.$route.query.record_id,
      class_info: {
        class_info: {},
      },
      session_info: {
        session_info: {},
      },
      content_info: {
        content_info: {},
      },
      quiz_info: {},
      indexInfo: {
        session_index: 0,
        content_index: 0,
        quiz_index: 0,
        quiz_pos: "1.1",
      },
      collection_info: {},
      classCatalogues: [],
      posMaps: {},
      loadingInstance: undefined,
    };
  },
  mounted() {
    this.exam_content_height = 92 - this.exam_header_height;
    this.requestClassCatalogue();
  },
  methods: {
    refreshMath() {
      console.log(1111, window.MathJax.Hub);
      this.$nextTick(() => {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, "app"]);
      });
    },
    goCollectionList() {
      this.$router.push({
        path: "/collection-list",
        query: {
          class_id: this.class_id,
        },
      });
    },
    handleCollectionClick(params) {
      if (params.type == "collection") {
        this.showCollectionDialog = true;
      } else {
        //remove collection
        this.$alert("Are you sure remove this collection？", "Tips", {
          confirmButtonText: "Confirm",
          callback: (action) => {
            if (action == "confirm") {
              removeCollectionQuiz(this.collection_info._id.$id).then((res) => {
                this.collection_info = {};
                this.$message.success("Remove Collection Success");
              });
            }
          },
        });
      }
    },
    doCollectionQuiz() {
      if (this.collection_note == "") {
        this.$message.error("Please Input ");
        return;
      }
      let params = {
        student_id: this.user_id,
        class_id: this.class_id,
        session_id: this.session_info._id.$id,
        content_id: this.content_info._id.$id,
        quiz_id: this.quiz_info._id.$id,
        note: this.collection_note,
      };
      collectionQuiz(params).then((res) => {
        this.$message.success("Collection Success");
        this.showCollectionDialog = false;
        this.collection_info = res.data.data;
      });
    },

    goHome() {
      this.$router.go(-1);
    },
    fetchData(showLoading) {
      let request_params = {
        type: "refresh",
        student_id: this.user_id,
        record_id: this.record_id,
        session_id: "",
        content_id: "",
        quiz_index: "",
        content_index: "",
        session_index: "",
      };
      this.gotoRequest(showLoading, request_params);
    },
    requestClassCatalogue() {
      this.showLoadingView("Loading");
      this.posMaps = {};
      this.classCatalogues = [];
      getClassCatalogue(this.user_id, this.class_id).then((res) => {
        let result = res.data.data;
        for (let i = 0; i < result.length; i++) {
          let session = result[i];
          let map = {};
          session["show_pos"] = i + 1;
          for (let j = 0; j < session["contents"].length; j++) {
            let content = session["contents"][j];
            let show_pos = i + 1 + "." + (j + 1);
            session["contents"][j]["show_pos"] = show_pos;
            map[content._id.$id] = show_pos;
          }
          this.posMaps[session._id.$id] = map;
          this.classCatalogues.push(session);
        }
        this.fetchData(false);
      });
    },
    goShowSpecialQuestion(params) {
      this.showNavMenuDrawer = false;
      let request_params = {
        type: "special",
        student_id: this.user_id,
        record_id: this.record_id,
        session_id: params.session._id.$id,
        content_id: params.content._id.$id,
        quiz_index: 0,
        content_index: params.content_index,
        session_index: params.session_index,
      };
      this.gotoRequest(true, request_params);
    },
    goReviewQuestion(params) {
      let request_params = {
        type: params.type,
        student_id: this.user_id,
        record_id: this.record_id,
        session_id: this.session_info._id.$id,
        content_id: this.content_info._id.$id,
        quiz_index: params.quiz_index,
        content_index: this.indexInfo.content_index,
        session_index: this.indexInfo.session_index,
      };
      this.gotoRequest(true, request_params);
    },
    gotoRequest(showLoading, params) {
      if (showLoading) {
        this.showLoadingView("Loading");
      }
      requestClassRecordData(params).then((res) => {
        let result = res.data;
        if (result.code == 0) {
          this.handleResult(result.data);
        } else {
          this.$message.error(result.msg);
        }
        this.closeLoading();
      });
    },
    gotoSubmitAnswer() {
      let result = this.$refs["examOptionView"].getSelectAnswer();
      if (null == result) {
        return;
      }
      this.showLoadingView("Submitting");
      let content_index = this.indexInfo.content_index;
      let session_index = this.indexInfo.session_index;
      let params = {
        student_id: this.user_id,
        class_id: this.class_info._id.$id,
        session_id: this.session_info._id.$id,
        content_id: this.content_info._id.$id,
        quiz_id: this.quiz_info._id.$id,
        quiz_index: this.indexInfo.quiz_index,
        answer: result["answer"],
        correct: result["right"],
        content_index: this.indexInfo.content_index,
        session_index: this.indexInfo.session_index,
      };
      submitLearningAnswer(params).then((res) => {
        this.closeLoading();
        this.$message.success("Submitted successfully");
        this.updateClassCatalogues(session_index, content_index);
        this.fetchData(true);
      });
    },
    handleResult(response) {
      // if (this.toastFinished == false && response.class_finished == true) {
      //   this.toastFinished = true
      //   this.$message.success('This Class All Answer Finished')
      // }
      this.class_info = response.class_info;
      this.session_info = response.session_info;
      this.content_info = response.content_info;
      this.quiz_info = response.quiz_info;
      this.collection_info = response.collection_info;
      this.indexInfo.session_index = response.session_index;
      this.indexInfo.content_index = response.content_index;
      this.indexInfo.quiz_index = response.quiz_index;

      let session_id = this.session_info._id.$id;
      let content_id = this.content_info._id.$id;
      this.indexInfo.quiz_pos = this.posMaps[session_id][content_id];

      this.collection_note = "";

      console.log(123, this.quiz_info);
      this.refreshMath();
    },
    showLoadingView(text) {
      this.closeLoading();
      this.loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.8)",
        spinner: "el-icon-loading",
        text: text,
      });
    },
    closeLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    updateClassCatalogues(session_index, content_index) {
      let answer_counts =
        this.classCatalogues[session_index]["contents"][content_index][
          "answer_counts"
        ];
      answer_counts++;
      this.classCatalogues[session_index]["contents"][content_index][
        "answer_counts"
      ] = answer_counts;
    },
  },
};
</script>

<style>
.showCustomDialogStyle {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}
</style>

<style scoped lang="less">
::-webkit-scrollbar {
  display: none;
}

.btn-confirm-send {
  cursor: pointer;
  margin: 35px auto 0px;
  width: 70%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #092e7f;
  border-radius: 32px 32px 32px 32px;
  font-size: 24px;
  // font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: bold;
  color: #ffffff;
}

.dialog-container {
  margin: 0 auto;
  text-align: center;
  width: 35%;
  max-width: 720px;
  padding: 30px 0px;
  box-sizing: border-box;
  background-color: white;
}

.collection-container {
  margin-left: 20px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  background: #ffa900;
  border-radius: 6px 6px 6px 6px;
  font-size: 20px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #ffffff;
}

.content-menu {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #092e7f;
  width: 124px;
  height: 44px;
  border-radius: 6px 6px 6px 6px;
  font-size: 20px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #ffffff;
}

.exam-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 92vh;
}

.exam-content {
  box-sizing: border-box;
  padding-left: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

::-webkit-scrollbar {
  display: none;
}

.exam-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

/deep/ .el-drawer__header {
  font-size: 20px;
  // font-family: AlibabaPuHuiTi-2-75-SemiBold;
  color: #333333;
}

/deep/ .el-drawer__container {
  top: 8vh;
}

/deep/ .el-drawer {
  width: 38% !important;
}
</style>
